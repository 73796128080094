import { EsercenteLead } from '../esercente/esercente-lead.impl';
import { Attivita } from '../attivita/attivita.impl';
import { DatiFatturazione } from '../datiFatturazione/datiFatturazione.impl';

export class DatiRegistrazione {
  constructor(
    public esercente: EsercenteLead,
    public attivita: Attivita,
    public datiFatturazione: DatiFatturazione
  ) {}
}
