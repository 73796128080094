<div class="social-container">
  <ul class="list-unstyled">
    <a href="https://play.google.com/store/apps/details?id=it.taal.reservare" target="_blank">
      <li>
        <i class="fab fa-google-play"></i>
      </li>
    </a>
    <a href="https://apps.apple.com/it/app/reservare/id1523702103?l=en" target="_blank">
      <li>
        <i class="fab fa-app-store"></i>
      </li>
    </a>
    <a href="https://www.facebook.com/" target="_blank">
      <li>
        <i class="fab fa-facebook-f"></i>
      </li>
    </a>
    <a href="https://www.instagram.com/" target="_blank">
      <li>
        <i class="fab fa-instagram"></i>
      </li>
    </a>
    <li (click)="openModal(template)">
      <i class="fa fa-envelope"></i>
    </li>
  </ul>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Contact Us</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="contact-form" class="contact-form" name="contact-form" method="post"
          action="https://nexadata.it/reservare/contact.php">

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input type="text" name="name" class="form-control" required placeholder="Nome e Cognome">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input type="company" name="company" class="form-control"
                   placeholder="Comune o azienda (facoltativo)">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input type="email" name="the_address" class="form-control" required
                   placeholder="Inserisci la tua e-mail">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <textarea type="text" name="message" class="form-control"
                      style="resize: none">Lascia un tuo messaggio...</textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <recaptcha class="d-flex justify-content-center"
                     [(ngModel)]="myRecaptcha" [ngModelOptions]="{standalone: true}"
          ></recaptcha>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <div class="form-group text-center">
            <button id="form-submit" type="submit" [disabled]="!myRecaptcha" class="btn btn-info">Invia messaggio
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</ng-template>
