import { Injectable } from '@angular/core';
import { Subscription } from '../../models/subscription/subscription';
import { SUBSCRIPTIONS } from '../../mock/subscription.mock';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor() {}

  getSubscriptions(): Promise<Subscription[]> {
    return new Promise((res, rej) => res(SUBSCRIPTIONS));
  }
}
