<div class="container">
  <div class="card w-100">
    <div class="card-body card-reservare">
      <form [formGroup]="fatturazioneForm">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="ragioneSociale" class="control-label required">Ragione Sociale</label>
              <input
                type="text"
                class="form-control"
                id="ragioneSociale"
                autocomplete="off"
                formControlName="ragioneSociale"
                name="ragioneSociale"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="piva" class="control-label required">P. IVA</label>
              <input
                type="text"
                class="form-control"
                id="piva"
                autocomplete="off"
                formControlName="PIVA"
                name="piva"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="codiceDestinatario" class="control-label required">Codice Destinatario</label>
              <input
                type="text"
                class="form-control"
                id="codiceDestinatario"
                minlength="7"
                maxlength="7"
                autocomplete="off"
                formControlName="codiceDestinatario"
                name="codiceDestinatario"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="pec" class="control-label required">PEC</label>
              <input
                type="text"
                class="form-control"
                id="pec"
                autocomplete="off"
                formControlName="PEC"
                name="pec"
              />
              <small
                *ngIf="(fatturazioneForm.get('PEC').hasError('email') && fatturazioneForm.get('PEC').touched)"
                class="text-danger">Inserire una mail valida</small>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label for="indirizzo" class="control-label required">Indirizzo</label>
              <input
                type="text"
                class="form-control"
                id="indirizzo"
                autocomplete="off"
                formControlName="indirizzo"
                name="indirizzo"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2">
            <div class="form-group">
              <label for="cap" class="control-label required">CAP</label>
              <input
                type="text"
                class="form-control"
                id="cap"
                minlength="5"
                maxlength="5"
                autocomplete="off"
                formControlName="CAP"
                name="cap"
              />
              <small
              *ngIf="(fatturazioneForm.get('CAP').hasError('pattern') && fatturazioneForm.get('CAP').touched)"
              class="text-danger">Inserire un CAP valido</small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group ng-autocomplete w-100">
              <input type="hidden" formControlName="idCitta"/>
              <label for="comuni" class="control-label required">Città</label>
              <ng-autocomplete
                id="comuni"
                [data]="comuniToShow"
                [searchKeyword]="keyword"
                (selected)="selectEventFatturazione($event)"
                (inputChanged)="onChangeSearch($event)"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.DESCRIZIONE"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound!"></div>
              </ng-template>
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2">
            <div class="form-group">
              <label for="provincia" class="control-label required">Provincia</label>
              <input
                type="text"
                minlength="2"
                maxlength="2"
                class="form-control"
                id="provincia"
                autocomplete="off"
                formControlName="provincia"
                oninput="this.value = this.value.toUpperCase()"
                name="provincia"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label for="telefono" class="control-label required">Telefono</label>
              <input
                type="text"
                class="form-control"
                id="telefono"
                autocomplete="off"
                formControlName="telefono"
                name="telefono"
              />
              <small
                *ngIf="(fatturazioneForm.get('telefono').hasError('pattern') && fatturazioneForm.get('telefono').touched)"
                class="text-danger">Inserire un numero telefonico valido. Se specificato il prefisso internazionale usare "+39"</small>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label for="email" class="control-label required">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                autocomplete="off"
                formControlName="email"
                name="email"
              />
              <small
                *ngIf="(fatturazioneForm.get('email').hasError('email') && fatturazioneForm.get('email').touched)"
                class="text-danger">Inserire una mail valida</small>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12 text-right text-center-reservare">
          <!--<button (click)="mock()">MOOOOCK</button>-->
          <button type="button" class="btn-reservare-step-back mr-2" (click)="onBack()"><span>Indietro</span></button>
          <button type="button" class="btn-reservare-step" (click)="onNext()" [disabled]="!fatturazioneForm.valid">
            <span>Avanti</span></button>
        </div>
      </div>
    </div>
  </div>
</div>
