<div class="mainScollingArea">
  <section
    class="d-flex justify-content-center align-items-center abbonamento-reservare bottommargin-reservare"
  >
    <div class="container">
      <app-stepper [step]="step"></app-stepper>
      <div class="mt-5">
        <app-step-anagrafici
          [hidden]="step !== 1"
          (esercente)="setEsercente($event)"
        ></app-step-anagrafici>
        <app-step-struttura
          [hidden]="step !== 2"
          (struttura)="setStruttura($event)"
          [comuni]="comuni"
          (back)="goBack($event)"
          [tipologieArea]="tipologieArea"
        ></app-step-struttura>
        <app-step-fatturazione
          [hidden]="step !== 3"
          (fatturazione)="setFatturazione($event)"
          (back)="goBack($event)"
          [comuni]="comuni"
        ></app-step-fatturazione>
        <app-stripe
          [hidden]="step !== 4"
          [formDatas]="formDatas"
          [subscription]="subscription"
          (back)="goBack($event)"
        ></app-stripe>
      </div>
    </div>
  </section>
</div>
