export class Attivita {
  constructor(
    public nomeStruttura: string,
    public descrizioneAttivita: string,
    public indirizzo: string,
    public CAP: string,
    public idCitta: number,
    public PROVINCIA: string,
    public telefono: string,
    public sitoWeb: string,
    public mail: string,
    public id?: number
  ) {}
}
