import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {DatiFatturazione} from '../../../../models/datiFatturazione/datiFatturazione.impl';
import {Comune} from '../../../../models/Comune/comune.impl';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-step-fatturazione',
  templateUrl: './step-fatturazione.component.html',
  styleUrls: ['./step-fatturazione.component.css']
})
export class StepFatturazioneComponent implements OnInit {

  @Input() comuni: Comune[] = [];
  @Output() fatturazione = new EventEmitter();
  @Output() back = new EventEmitter();

  comuniToShow = [];
  keyword = 'DESCRIZIONE';

  fatturazioneForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const mobnumPattern = '^((\\+39-?)|0)?[0-9]{9,13}$';
    const capPattern = '^[0-9]{5}$';
    this.fatturazioneForm = this.formBuilder.group({
      ragioneSociale: new FormControl('', [Validators.required]),
      PIVA: new FormControl('', [Validators.required]),
      codiceDestinatario: new FormControl('', [Validators.required, Validators.maxLength(7), Validators.minLength(7)]),
      PEC: new FormControl('', [Validators.required, Validators.email]),
      indirizzo: new FormControl('', [Validators.required]),
      CAP: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern(capPattern)]),
      idCitta: new FormControl('', [Validators.required]),
      provincia: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]),
      telefono: new FormControl('', [Validators.required, Validators.pattern(mobnumPattern)]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  selectEventFatturazione(item) {
    this.fatturazioneForm.controls['idCitta'].setValue(item.ID);
  }

  onChangeSearch(search: string) {
    if (search && search.length > 0) {
      this.comuniToShow = this.comuni.filter((el) =>
        el.DESCRIZIONE.trim().toUpperCase().startsWith(search.toUpperCase())
      );
      return;
    }
    this.comuniToShow = [];
  }

  onNext(): void {
    if (this.fatturazioneForm.valid) {
      const modelDatiFatturazione = new DatiFatturazione(
        this.fatturazioneForm.get('ragioneSociale').value,
        this.fatturazioneForm.get('PIVA').value,
        this.fatturazioneForm.get('codiceDestinatario').value,
        this.fatturazioneForm.get('PEC').value,
        this.fatturazioneForm.get('indirizzo').value,
        this.fatturazioneForm.get('CAP').value,
        this.fatturazioneForm.get('idCitta').value,
        this.fatturazioneForm.get('provincia').value,
        this.fatturazioneForm.get('telefono').value,
        this.fatturazioneForm.get('email').value);
      this.fatturazione.emit(modelDatiFatturazione);
    }
  }

  onBack(): void {
    this.back.emit();
  }

  mock(): void {
    this.fatturazioneForm.controls['ragioneSociale'].setValue('a');
    this.fatturazioneForm.controls['PIVA'].setValue('123456');
    this.fatturazioneForm.controls['codiceDestinatario'].setValue('0000000');
    this.fatturazioneForm.controls['PEC'].setValue('PEC@PEC.it');
    this.fatturazioneForm.controls['indirizzo'].setValue('indirizzo');
    this.fatturazioneForm.controls['CAP'].setValue(12345);
    this.fatturazioneForm.controls['idCitta'].setValue('1');
    this.fatturazioneForm.controls['provincia'].setValue('AB');
    this.fatturazioneForm.controls['telefono'].setValue('3280000000');
    this.fatturazioneForm.controls['email'].setValue('email@email.it');
  }
}
