export class FullRegistration {
  constructor() {}
  public id: number;
  public CODICELINGUA: string;
  public PASSWORD: string;
  public NOME: string;
  public COGNOME: string;
  public EMAIL_ESERCENTE: string;
  public CELLULARE: string;
  public DESCRIZIONE: string;
  public CODICETIPOLOGIAAREA: string;
  public INDIRIZZO_AREA: string;
  public CAP_AREA: string;
  public TELEFONO_AREA: string;
  public SITOWEB: string;
  public EMAIL_AREA: string;
  public RAGIONESOCIALE: string;
  public PARTITAIVA: string;
  public CODICEDESTINATARIO: string;
  public PEC: string;
  public INDIRIZZO_FATTURAZIONE: string;
  public CAP_FATTURAZIONE: string;
  public TELEFONO_FATTURAZIONE: string;
  public EMAIL_FATTURAZIONE: string;
  public CODICEPROVINCIA_AREA: string;
  public CODICEPROVINCIA_FATTURAZIONE: string;
  public IDCOMUNE_FATTURAZIONE: number;
  public IDCOMUNE_AREA: number;
  public FLAGGDPR: number;
}
