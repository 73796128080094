import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {StripeService} from 'src/app/services/stripe/stripe.service';
import {FullRegistration} from 'src/app/models/fullRregistration/fullRegistration.impl';
import {Subscription} from 'src/app/models/subscription/subscription';
import {ToastrService} from "ngx-toastr";

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  @Input() formDatas: FullRegistration;
  @Input() subscription: Subscription;
  @Output() back = new EventEmitter();

  card: any;

  constructor(private stripeService: StripeService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.card = elements.create('card', {
      style,
      value: {hidePostalCode: true},
    });
    this.card.mount('#card-element');
  }

  submitStripe() {
    const dat: FullRegistration = this.formDatas;
    stripe.createToken(this.card).then((result) => {
      if (result.error) {
        // Inform the user if there was an error.
        const errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        stripe
          .createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: 'billingName',
            },
          })
          .then((result) => {
            this.stripeService
              .payWithStripe(result.paymentMethod, dat, this.subscription)
              .subscribe((data) => {
                this.toastr.success('Pagamento effettuato con successo');
                setInterval(() => {
                  window.location.href = "https://app.reservare.it/";
                }, 3000);
              });
          });
      }
    });
  }

  onBack(): void {
    this.back.emit();
  }
}
