<section class="infoSection d-flex justify-content-center align-items-center" id="content">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-8 offset-sm-0 offset-md-2 offset-lg-2 text-center">
        <h2 class="sectionTitle">
          Che cos'e' Reservare?
        </h2>
        <p>E’ un sistema di prenotazione rapida on line, standard PNR, per gli accessi in aree pubbliche o private
          (spiagge, parchi, eventi etc) e di monitoraggio delle presenze.</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 col-md-12 col-lg-10 offset-sm-0 offset-md-0 offset-lg-1 d-flex justify-content-center align-items-center">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div class="card w-100">
              <div class="card-body card-reservare text-center">
                <i class="far fa-hand-point-right"></i>
                <h4>
                  Semplice
                </h4>
                <p>
                  Prenotazione <b>agevole</b> e <b>immediata</b>.<br/>
                  Una volta completata la prenotazione verrà prodotto un biglietto contenente tutti i dati (componenti del gruppo, data, destinazione ecc) da stampare o memorizzare sullo smartphone.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div class="card w-100">
              <div class="card-body card-reservare text-center">
                <i class="fa fa-lock"></i>
                <h4>
                  Sicuro</h4>
                <p>
                  Permette agli addetti di monitorare costantemente la presenza di persone nelle aree monitorate,
                  ottimizzando l’impiego delle risorse disponibili e fornendo dati statistici e report sull’andamento delle
                  attività.<br/><br/>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-5 mr-auto ml-auto">
            <div class="card w-100">
              <div class="card-body card-reservare text-center">
                <i class="fa fa-fighter-jet"></i>
                <h4>
                  Veloce
                </h4>
                <p>
                  Basta un click per scegliere la propria destinazione!<br/>
                  Con Reservare è possibile verificare i posti disponibili nei luoghi di interesse, l’eventuale presenza di accessi per disabili e la presenza di aree atte ai possessori di animali.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
