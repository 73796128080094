import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Attivita} from '../../../../models/attivita/attivita.impl';
import {TipologiaArea} from '../../../../models/tipologiaArea/tipologiaArea.impl';
import {LookupService} from '../../../../services/lookups/lookups.service';
import {Comune} from '../../../../models/Comune/comune.impl';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EsercenteLead} from '../../../../models/esercente/esercente-lead.impl';

@Component({
  selector: 'app-step-struttura',
  templateUrl: './step-struttura.component.html',
  styleUrls: ['./step-struttura.component.css']
})

export class StepStrutturaComponent implements OnInit {

  @Output() struttura = new EventEmitter();
  @Input() comuni: Comune[] = [];
  @Input() tipologieArea: TipologiaArea[] = [];
  @Output() back = new EventEmitter();

  idCitta: number;
  comuniToShow = [];
  keyword = 'DESCRIZIONE';

  strutturaForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const mobnumPattern = '^((\\+39-?)|0)?[0-9]{9,13}$';
    const capPattern = '^[0-9]{5}$';
    this.strutturaForm = this.formBuilder.group({
      nomeStruttura: new FormControl('', [Validators.required]),
      descrizioneAttivita: new FormControl('', [Validators.required]),
      indirizzo: new FormControl('', [Validators.required]),
      CAP: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern(capPattern)]),
      idCitta: new FormControl('', [Validators.required]),
      provincia: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]),
      telefono: new FormControl('', [Validators.required, Validators.pattern(mobnumPattern)]),
      sitoWeb: new FormControl(''),
      email: new FormControl('', [Validators.email]),
    });
  }


  selectEvent(item): void {
    this.strutturaForm.controls['idCitta'].setValue(item.ID);
  }

  onChangeSearch(search: string): void {
    if (search && search.length > 0) {
      this.comuniToShow = this.comuni.filter((el) =>
        el.DESCRIZIONE.trim().toUpperCase().startsWith(search.toUpperCase())
      );
      return;
    }
    this.comuniToShow = [];
  }

  onNext(): void {
    if (this.strutturaForm.valid) {
      const modelStruttura =
        new Attivita(
          this.strutturaForm.get('nomeStruttura').value,
          this.strutturaForm.get('descrizioneAttivita').value,
          this.strutturaForm.get('indirizzo').value,
          this.strutturaForm.get('CAP').value,
          this.strutturaForm.get('idCitta').value,
          this.strutturaForm.get('provincia').value,
          this.strutturaForm.get('telefono').value,
          this.strutturaForm.get('sitoWeb').value ? this.strutturaForm.get('sitoWeb').value : '',
          this.strutturaForm.get('email').value
        );
      this.struttura.emit(modelStruttura);
    }
  }

  onBack(): void {
    this.back.emit();
  }

  mock(): void {
    this.strutturaForm.controls['nomeStruttura'].setValue('a');
    this.strutturaForm.controls['descrizioneAttivita'].setValue('RIST');
    this.strutturaForm.controls['indirizzo'].setValue('indirizzo');
    this.strutturaForm.controls['CAP'].setValue(12345);
    this.strutturaForm.controls['idCitta'].setValue(1);
    this.strutturaForm.controls['provincia'].setValue('AB');
    this.strutturaForm.controls['telefono'].setValue('3280000000');
  }
}

