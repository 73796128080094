<section class="mainSection d-flex justify-content-center align-items-center">
  <div class="container">
    <div class="row">
      <div
        class="col-sm-12 col-md-5 col-lg-5 offset-sm-0 offset-md-1 offset-lg-1 pt-5 toppadding-reservare"
      >
        <h1 class="mainSectionTitle">RESERVARE</h1>
        <h3 class="mainSectionSubTitle">
          La scelta sicura, a portata di click
        </h3>
        <p class="text-center-reservare">
          Taal presenta Reservare, un software gestionale in Cloud per gestire l’accesso ad aree controllate o monitorate.
          Reservare permette di gestire flussi e affollamento nelle spiagge libere, spiagge in concessione, ristoranti, attività commerciali o eventi pubblici.
        </p>
        <p class="callToAction text-center-reservare">
          <a href="javascript: void(0);" [ngxScrollTo]="'#content'"
            ><span><i class="fa fa-play"></i></span
          ></a>
          <span class="callToActionLabel">SCOPRI DI PIU'</span>
        </p>
      </div>
      <div
        class="col-sm-12 col-md-5 col-lg-5 mb-5 d-flex pt-5 toppadding-reservare justify-content-center align-items-center"
      >
        <img src="assets/mobile.png" class="w-100" />
      </div>
    </div>
  </div>
</section>
