import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubscriptionComponent} from './components/subscription/subscription.component';
import {Routes, RouterModule} from '@angular/router';
import {RegisterComponent} from './components/register/register.component';
import {HomeComponent} from './components/home/home.component';
import {SuccessPageComponent} from "./components/subscription-form/components/success-page/success-page.component";
import {SubscriptionInfoComponent} from "./components/subscription-info/subscription-info.component";

const routes: Routes = [
  {path: '', component: HomeComponent },
  {path: 'subscription-information', component: SubscriptionInfoComponent},
  {path: 'subscription', component: SubscriptionComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'success', component: SuccessPageComponent},
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
