<div class="container">
  <div class="card w-100">
    <div class="card-body card-reservare">
      <form [formGroup]="strutturaForm">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="form-group">
              <label for="nomeStruttura" class="control-label required">Nome Struttura</label>
              <input
                type="text"
                class="form-control"
                id="nomeStruttura"
                autocomplete="off"
                formControlName="nomeStruttura"
                name="nomeStruttura"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="descrizioneAttivita" class="control-label required">Descrizione attività</label>
              <select
                id="descrizioneAttivita"
                class="form-control"
                autocomplete="off"
                formControlName="descrizioneAttivita"
                name="descrizioneAttivita"
              >
                <option
                  *ngFor="let attivita of tipologieArea"
                  [value]="attivita.CODICE"
                >
                  {{ attivita.DESCRIZIONE | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="indirizzo" class="control-label required">Indirizzo</label>
              <input
                type="text"
                class="form-control"
                id="indirizzo"
                autocomplete="off"
                formControlName="indirizzo"
                name="indirizzo"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2">
            <div class="form-group">
              <label for="CAP" class="control-label required">CAP</label>
              <input
                type="text"
                class="form-control"
                id="CAP"
                minlength="5"
                maxlength="5"
                autocomplete="off"
                formControlName="CAP"
                name="CAP"
              />
              <small
                *ngIf="(strutturaForm.get('CAP').hasError('pattern') && strutturaForm.get('CAP').touched)"
                class="text-danger">Inserire un CAP valido</small>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="comuni" class="control-label required">Città</label>
              <input type="hidden" formControlName="idCitta" />
              <ng-autocomplete
                id="comuni"
                [data]="comuniToShow"
                [searchKeyword]="keyword"
                (selected)="selectEvent($event)"
                (inputChanged)="onChangeSearch($event)"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.DESCRIZIONE"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound!"></div>
              </ng-template>
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2">
            <div class="form-group">
              <label for="provincia" class="control-label required">Provincia</label>
              <input
                type="text"
                minlength="2"
                maxlength="2"
                class="form-control"
                id="provincia"
                autocomplete="off"
                formControlName="provincia"
                oninput="this.value = this.value.toUpperCase()"
                name="provincia"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="telefono" class="control-label required">Telefono</label>
              <input
                type="text"
                class="form-control"
                id="telefono"
                autocomplete="off"
                formControlName="telefono"
                name="telefono"
              />
              <small
                *ngIf="(strutturaForm.get('telefono').hasError('pattern') && strutturaForm.get('telefono').touched)"
                class="text-danger">Inserire un numero telefonico valido. Se specificato il prefisso internazionale usare "+39"</small>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="sitoWeb">Sito web</label>
              <input
                type="text"
                class="form-control"
                id="sitoWeb"
                autocomplete="off"
                formControlName="sitoWeb"
                name="sitoWeb"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                autocomplete="off"
                formControlName="email"
                name="email"
              />
              <small
                *ngIf="(strutturaForm.get('email').hasError('email') && strutturaForm.get('email').touched)"
                class="text-danger">Inserire una mail valida</small>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12 text-right text-center-reservare">
          <!--<button (click)="mock()">MOOOOCK</button>-->
          <button type="button" class="btn-reservare-step-back mr-2" (click)="onBack()"><span>Indietro</span></button>
          <button type="button" class="btn-reservare-step" (click)="onNext()" [disabled]="!strutturaForm.valid"><span>Avanti</span></button>
        </div>
      </div>
    </div>
  </div>
</div>

