<nav
  class="navbar navbar-reservare navbar-expand-lg navbar-light d-flex justify-content-between position-fixed w-100 header-reservare"
>
  <img src="assets/logo.png" style="width: 50px" />
  <a
    class="btn-reservare mr-sm-0 mr-md-2 mr-lg-2"
    href="https://app.reservare.it/"
    target="_blank"
    ><span>ACCEDI AL SERVIZIO</span></a
  >
</nav>
