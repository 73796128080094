import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {FullRegistration} from 'src/app/models/fullRregistration/fullRegistration.impl';
import {Subscription} from 'src/app/models/subscription/subscription';

const httpOptions = {
  headers: new HttpHeaders({}),
};

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private http: HttpClient) {
    httpOptions.headers = httpOptions.headers.set('Pragma', 'no-cache');
    httpOptions.headers = httpOptions.headers.set('Cache-Control', 'no-cache');
    httpOptions.headers = httpOptions.headers.set(
      'Content-Type',
      'application/json'
    );
  }

  base_url_taal = 'https://nmuwrvo9bd.execute-api.eu-west-1.amazonaws.com/Prod/api';
  base_url_fra = 'http://192.168.128.134:44315/api';
  base_url_locale = 'http://localhost:44315/api';
  stripeTest = '/generic/stripeTest';

  CreateCustomerUser = '/generic/stripe/CreateCustomerUser';

  testStripe(token: any, datas: FullRegistration) {
    return this.http.post(
      this.base_url_locale + this.stripeTest,
      {
        email_Stripe: datas.EMAIL_FATTURAZIONE,
        pm_id_Stripe: token.id,
        billing_address_Stripe: datas.INDIRIZZO_FATTURAZIONE,
        paymentTypeId: 3,
        par: datas,
      },
      httpOptions
    );
  }

  payWithStripe(
    token: any,
    datas: FullRegistration,
    subscription: Subscription
  ) {
    return this.http.post(
      this.base_url_taal + this.CreateCustomerUser,
      {
        email_Stripe: datas.EMAIL_FATTURAZIONE,
        pm_id_Stripe: token.id,
        billing_address_Stripe: datas.INDIRIZZO_FATTURAZIONE,
        paymentTypeId: subscription.id,
        par: datas,
      },
      httpOptions
    );
  }
}
