import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EsercenteLead } from '../../models/esercente/esercente-lead.impl';
import { Attivita } from 'src/app/models/attivita/attivita.impl';
import { DatiFatturazione } from 'src/app/models/datiFatturazione/datiFatturazione.impl';
import { DatiRegistrazione } from 'src/app/models/datiRegistrazione/datiRegistrazione.impl';
import { LookupService } from 'src/app/services/lookups/lookups.service';
import { FullRegistration } from 'src/app/models/fullRregistration/fullRegistration.impl';
import { Subscription } from 'src/app/models/subscription/subscription';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.css'],
})
export class SubscriptionFormComponent implements OnInit {
  @Input() subscription: Subscription;
  @Output() submitted = new EventEmitter<DatiRegistrazione>();

  datiRegistrazione: DatiRegistrazione = new DatiRegistrazione(
    new EsercenteLead('', '', '', '', '', '', 0),
    new Attivita('', '', '', '', 0, '', '', '', ''),
    new DatiFatturazione('', '', '', '', '', '', 0, '', '', '')
  );

  comuni = [];
  tipologieArea = [];

  showStripeCheckout = false;
  step: number = 1;

  formDatas = new FullRegistration();

  constructor(private lookupService: LookupService) {}

  ngOnInit() {
    this.getComuniAndTipologieArea();
  }

  manageStepBar() {
    const el = document.getElementsByClassName('active')[0];
    const el2 = document.getElementsByClassName('step' + this.step)[0];
    el.classList.remove('active');
    el2.classList.add('active');
  }

  setEsercente(event): void {
    this.datiRegistrazione.esercente = event;
    this.step++;
  }

  setStruttura(event): void {
    this.datiRegistrazione.attivita = event;
    this.step++;
  }

  setFatturazione(event): void {
    this.datiRegistrazione.datiFatturazione = event;
    this.formDatas = this.createDatiRegistrazione(this.datiRegistrazione);
    this.step++;
  }

  goBack(event): void {
    this.step--;
  }

  getComuniAndTipologieArea(): void {
    this.lookupService.get('ListaComuni').subscribe((coms: any) => {
      this.comuni = coms.result;
      this.lookupService.get('ListaTipologieArea').subscribe((tipAr: any) => {
        this.tipologieArea = tipAr.result;
      });
    });
    this.comuni = [];
    this.tipologieArea = [];
  }

  public createDatiRegistrazione(
    datiRegistrazione: DatiRegistrazione
  ): FullRegistration {
    const reg = new FullRegistration();
    //
    reg.CODICELINGUA = datiRegistrazione.esercente.codiceLingua;
    reg.PASSWORD = datiRegistrazione.esercente.password;
    reg.NOME = datiRegistrazione.esercente.nome;
    reg.COGNOME = datiRegistrazione.esercente.cognome;
    reg.EMAIL_ESERCENTE = datiRegistrazione.esercente.email;
    reg.CELLULARE = datiRegistrazione.esercente.cellulare;
    reg.FLAGGDPR = datiRegistrazione.esercente.FLAGGDPR;
    //
    reg.DESCRIZIONE = datiRegistrazione.attivita.nomeStruttura;
    reg.CODICETIPOLOGIAAREA = datiRegistrazione.attivita.descrizioneAttivita;
    reg.INDIRIZZO_AREA = datiRegistrazione.attivita.indirizzo;
    reg.CAP_AREA = datiRegistrazione.attivita.CAP;
    reg.TELEFONO_AREA = datiRegistrazione.attivita.telefono;
    reg.SITOWEB = datiRegistrazione.attivita.sitoWeb;
    reg.EMAIL_AREA = datiRegistrazione.attivita.mail;
    reg.IDCOMUNE_AREA = datiRegistrazione.attivita.idCitta;
    reg.CODICEPROVINCIA_AREA = datiRegistrazione.attivita.PROVINCIA;
    //
    reg.RAGIONESOCIALE = datiRegistrazione.datiFatturazione.ragioneSociale;
    reg.PARTITAIVA = datiRegistrazione.datiFatturazione.PIVA;
    reg.CODICEDESTINATARIO =
      datiRegistrazione.datiFatturazione.codiceDestinatario;
    reg.PEC = datiRegistrazione.datiFatturazione.PEC;
    reg.INDIRIZZO_FATTURAZIONE = datiRegistrazione.datiFatturazione.indirizzo;
    reg.CAP_FATTURAZIONE = datiRegistrazione.datiFatturazione.CAP;
    reg.TELEFONO_FATTURAZIONE = datiRegistrazione.datiFatturazione.telefono;
    reg.EMAIL_FATTURAZIONE = datiRegistrazione.datiFatturazione.mail;
    reg.IDCOMUNE_FATTURAZIONE = datiRegistrazione.datiFatturazione.idCitta;
    reg.CODICEPROVINCIA_FATTURAZIONE =
      datiRegistrazione.datiFatturazione.PROVINCIA;
    //
    return reg;
  }
}
