<div class="mainScollingArea">
  <section class="activationSection d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center reverse-reservare">
        <div class="col-12 text-center mb-2">
          <h2 class="sectionTitle">
            Ti abbiamo convinto?
          </h2>
          <p class="callToAction">
            <a routerLink="/subscription"><span><i class="fa fa-play"></i></span></a> <span class="callToActionLabel">ABBONATI ORA</span>
          </p>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
