import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {EsercenteLead} from '../../../../models/esercente/esercente-lead.impl';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-step-anagrafici',
  templateUrl: './step-anagrafici.component.html',
  styleUrls: ['./step-anagrafici.component.css']
})
export class StepAnagraficiComponent implements OnInit {

  @Output() esercente = new EventEmitter<EsercenteLead>();

  confermaPassword: string = '';
  confermaMail: string = '';
  showPrivacyMandatory: boolean = false;

  anagraficaForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const mobnumPattern = '^((\\+39-?)|0)?[0-9]{9,12}$';
    const passwordPattern = '^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+])[A-Za-z\\d][A-Za-z\\d!@#$%^&*()_+]{7,19}$';
    this.anagraficaForm = this.formBuilder.group({
      nome: new FormControl('', [Validators.required]),
      cognome: new FormControl('', [Validators.required]),
      cellulare: new FormControl('', [Validators.required, Validators.pattern(mobnumPattern)]),
      emails: this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        confermaMail: ['', [Validators.required]],
      }, {validator: this.emailMatching}),
      passwords: this.formBuilder.group({
        password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
        confermaPassword: ['', [Validators.required]],
      }, {validator: this.passwordMatching}),
      FLAGGDPR: new FormControl('', [Validators.required]),
    });
  }

  emailMatching(c: AbstractControl): { invalid: boolean } {
    if (c.get('email').value !== c.get('confermaMail').value) {
      return {invalid: true};
    }
  }

  passwordMatching(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confermaPassword').value) {
      return {invalid: true};
    }
  }

  onCheckboxChange(e): void {
    this.showPrivacyMandatory = !this.anagraficaForm.get('FLAGGDPR').value;
  }

  onNext(): void {
    if (this.anagraficaForm.valid) {
      const modelEsercenteLead =
        new EsercenteLead(
          'it',
          this.anagraficaForm.get(['passwords', 'password']).value,
          this.anagraficaForm.get('nome').value,
          this.anagraficaForm.get('cognome').value,
          this.anagraficaForm.get('cellulare').value,
          this.anagraficaForm.get(['emails', 'email']).value,
          this.anagraficaForm.get('FLAGGDPR').value ? 1 : 0
        );
      this.esercente.emit(modelEsercenteLead);
    }
  }

  mock(): void {
    this.anagraficaForm.controls['passwords'].setValue({
      password: 'Taprolano88!',
      confermaPassword: 'Taprolano88!'
    });
    this.anagraficaForm.controls['emails'].setValue({
      email: 'mg@mg.it',
      confermaMail: 'mg@mg.it'
    });
    this.anagraficaForm.controls['nome'].setValue('a');
    this.anagraficaForm.controls['cognome'].setValue('b');
    this.anagraficaForm.controls['cellulare'].setValue('3280000123');
    this.anagraficaForm.controls['FLAGGDPR'].setValue(true);
  }
}
