import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from './subscription.service';
import { Subscription } from '../../models/subscription/subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  subscriptions: Subscription[];

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getSubscriptions();
  }

  getSubscriptions(): void {
    this.subscriptionService
      .getSubscriptions()
      .then((subs) => (this.subscriptions = subs));
  }

  subscribe(sub: Subscription) {
    debugger;
    this.router.navigate(['register'], {
      state: { subscription: sub },
    });
  }
}
