<footer class="d-flex justify-content-center align-items-center">
  <div class="row">
    <div class="col-12 text-center">
      Powered by <a href="http://www.taal.it" target="_blank">Taal S.R.L</a>
    </div>
  </div>

  <div class="cookieMessage col-sm-12 col-md-4 col-lg-4" *ngIf="!isConsented">
    <div class="row">
      <div class="col-12">
        Questo sito utilizza cookie tecnici, analytics e di terze parti. Proseguendo nella navigazione accetti
        l’utilizzo dei
        cookie. Consulta la <a href="javascript:void(0);" (click)="openModal(template)">Privacy Policy</a> per saperne
        di
        piu'.
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="button" class="d-block btn-reservare-step float-right" (click)="consent(true,$event)"><span>Accetta</span>
        </button>
      </div>
    </div>
  </div>
</footer>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Privacy Policy</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      La presente Informativa sulla Privacy illustra le modalità con le quali la societ&aacute; TAAL
      srl
      (in seguito “<b>Titolare</b>”) tratta i dati personali dell’utente raccolti tramite il sito web
      <a href="http://www.reservare.it">www.reservare.it</a> (in seguito “<b>Sito</b>”). In
      particolare, in
      conformità con il Regolamento generale
      sulla protezione dei dati dell’UE n. 2016/679 (in seguito “<b>REGOLAMENTO</b>”), la presente
      fornisce specifiche informazioni sulla tipologia, sulle modalità di utilizzo, sulla condivisione
      dei dati personali e su come esercitare i diritti previsti in relazione all’attività di
      trattamento.
    </p>
    <h5>TITOLARE DEL TRATTAMENTO</h5>
    <p>Il Titolare del Trattamento è Francesco Maria Ermani, E-mail <a
      href="mailto:fmermani@gmail.com">fmermani@gmail.com</a></p>
    <h5>QUALI DATI PERSONALI RACCOGLIAMO</h5>
    <p>
      Qualora l’utente desideri usufruire dei servizi offerti dal sito, ad esempio quando si
      effettuano
      acquisti tramite il portale e-Commerce o quando si richiede di ricevere informazioni, potrà
      volontariamente comunicare i seguenti dati mediante la compilazione dei moduli preposti.
    </p>
    <ul>
      <li>
        Dati di contatto – si tratta di informazioni utili ad identificare il soggetto e vengono
        utilizzate per spedire i prodotti acquistati, fornire informazioni promozionali, rispondere
        alle domande e ai dubbi sollevati. Tali dati comprendono: nome, cognome, numero di
        telefono, indirizzo e-mail, ragione sociale, indirizzo di fatturazione e di spedizione.
      </li>
      <li>
        Dati di accesso – si tratta di informazioni finalizzate alla creazione ed amministrazione
        del profilo utente in cui è possibile effettuare una serie di attività quali modificare i
        dettagli dell’account. Tali dati comprendono: password e nome utente.
      </li>
    </ul>
    <p>
      Al momento dell’interazione con il sito web, inoltre, alcuni dati vengono raccolti
      automaticamente dai dispositivi o dai browser dell’utente. Specifiche informazioni su queste
      pratiche sono fornite alla policy cookies del presente sito.
    </p>
    <p>
      Questi dati includono:
    </p>
    <ul>
      <li>
        Dati di navigazione – si tratta di informazioni tecniche relative alla navigazione in
        internet dell’utente. Non sono raccolte per essere associate a interessati identificati, ma
        che per loro stessa natura potrebbero permettere di identificare gli utenti attraverso
        elaborazioni ed associazioni con dati detenuti da terzi. In questa categoria rientrano:
        indirizzi IP, sistema operativo, dati che identificano il browser e la versione in uso,
        dettagli del dispositivo in uso, dati di accesso alla rete.
      </li>
      <li>
        Cookies – I cookie sono piccoli file di testo che vengono memorizzati sul computer o
        sul dispositivo mobile quando si visita un sito Web. Tali strumenti permettono di
        registrare alcuni parametri e dati comunicati al sistema informatico attraverso il browser
        utilizzato e, pertanto, consentono un’analisi delle abitudini dell’utente nell’utilizzo del
        Sito (ad esempio monitoraggio di sessioni o memorizzazione delle preferenze).
      </li>
    </ul>
    <h5>PERCHÉ TRATTIAMO I DATI PERSONALI</h5>
    <p>
      Raccogliamo, utilizziamo e conserviamo i dati personali dell’utente per le finalità di seguito
      indicate.
    </p>
    <ul>
      <li>
        Inviare materiale informativo,– I dati raccolti saranno utilizzati per comunicazioni
        inerenti la prenotazione effettuata sul sito.
      </li>
      <li>
        Rispondere alle richieste degli utenti – I dati raccolti saranno utilizzati per dare
        esecuzione alle istanze e/o interrogazioni inoltrate tramite l’apposita sezione “contatti”
        presente sul sito.
      </li>
      <li>
        Svolgere e migliorare l’attività, i prodotti e i servizi – I dati raccolti saranno
        utilizzati per l’ordinaria amministrazione dell’attività e per gestire problemi tecnici e
        migliorare l’esperienza dell’utente sul sito.
      </li>
    </ul>
    <h5>FONDAMENTO LEGALE</h5>
    <p>Il titolare effettuerà il trattamento dei dati personali sulle seguenti basi giuridiche.</p>
    <ul>
      <li>Il consenso – La ricezione di comunicazioni informative, pubblicitarie e promozionali o
        la partecipazione al servizio di newsletter è subordinato allo specifico consenso
        prestato dall’utente nell’apposita sezione del sito. Tale consenso potrà essere revocato
        in qualsiasi momento.
      </li>
      <li>Obblighi contrattuali – I dati personali verranno elaborati per la prenotazione di uno
        spazio nelle spiagge libere o ad un evento organizzato dal comune.
      </li>
      <li>Interessi legittimi – I dati personali potranno essere trattati per perseguire interessi
        legittimi del Titolare in materia di normativa per la prevenzione del contagio da
        Coronavirus.
      </li>
    </ul>
    <h5>CONDIVISIONE DEI DATI PERSONALI</h5>
    <p>Il Comune condivide i dati personali conferiti con:</p>
    <ul>
      <li>Il personale dipendente – I dipendenti del Titolare trattano i dati personali
        esclusivamente in relazione alle mansioni da loro svolte e per le finalità sopra
        espresse.
      </li>
      <li>Fornitori di servizi – Soggetti terzi che operano in collaborazione col comune
        nell’erogazione dei servizi richiesti come, a titolo esemplificativo, addetti ai varchi,
        addetti alla sicurezza o soggetti convolti nell’organizzazione del sito.
      </li>
      <li>Autorità di regolamentazione e forze dell’ordine – Autorità governative e/o
        funzionari delle forze dell’ordine, se obbligatorio per legge o se richiesto per la
        protezione dei nostri legittimi interessi in conformità con la normativa applicabile.
      </li>
    </ul>
    <h5>TRASFERIMENTO DI DATI PERSONALI</h5>
    <p>I dati personali degli utenti oggetto di trattamento da parte del Titolare non vengono diffusi a
      soggetti indeterminati né trasferiti Paesi Terzi o a organizzazioni internazionali.</p>
    <h5>TEMPI DI CONSERVAZIONE DEI DATI PERSONALI</h5>
    <p>Il titolare conserverà i dati personali solamente per il tempo strettamente necessario alla
      gestione del servizio richiesto dall’utente o fino a quando richiesto da obblighi legali o
      contrattuali.</p>
    <p>Per ciò che concerne i dati trattati per finalità informative, promozionali e/o pubblicitarie,
      rilasciati previo consenso, saranno conservati fino al momento in cui è comunicato
      dall’interessato l’intenzione di revocare tale consenso.</p>
    <h5>DIRITTI RELATIVI AI DATI PERSONALI</h5>
    <p>L’utente ha la facoltà di esercitare, ai sensi degli articoli 15- 22 del Regolamento UE n.
      2016/679, i seguenti diritti:</p>

    <ol>
      <li>accesso ai dati personali – Ottenere la conferma dell’esistenza o meno di propri dati
        personali. Tale diritto comprende anche la possibilità di ricevere indicazioni circa
        l’origine dei dati personali, le finalità e le modalità del trattamento, le categorie dei
        dati, i
        destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno
        comunicati, il periodo di conservazione, nonché informazioni circa gli estremi
        identificativi del titolare, dei responsabili o dei soggetti ai quali i dati possono essere
        comunicati o che possono venirne a conoscenza;
      </li>
      <li>rettifica – Verificare che i dati personali in possesso del Titolare siano corretti e
        completi, in caso contrario procedere alla rettificazione o integrazione.
      </li>
      <li>cancellazione –  Richiedere l’eliminazione di tutti i dati presenti nei sistemi del
        Titolare.
      <li>limitazione del trattamento – Limitare o interrompere l’utilizzo dei dati personali per
        determinati scopi o finalità.
      </li>
      <li>portabilità dei dati – Ottenere una copia dei dati personali in un formato strutturato,
        di
        uso comune e leggibile da dispositivo automatico, e trasmetterli ad un altro titolare del
        trattamento senza impedimenti;
      </li>
      <li>opposizione – L’utente può opporsi, per motivi legittimi, al trattamento in tutto o in
        parte dei dati personali pertinenti allo scopo della raccolta o opporsi al trattamento dei
        dati personali per finalità pubblicitarie e promozionali
      </li>
      <li>revocare il consenso– Qualora è stato prestato specifico consenso al trattamento dei
        dati personali, l’utente, in qualsiasi momento, può revocare il consenso senza
        pregiudicare la liceità del trattamento effettuato fino a quel momento;
      </li>
      <li>reclamo a un’autorità di vigilanza –  Proporre reclamo all’ Autorità Garante per la
        protezione dei dati personali (garanteprivacy.it) contro il trattamento effettuato da Life
        Intimo s.r.l. mediante il sito.
      </li>
    </ol>
    <p>È possibile esercitare i diritti suesposti contattando il Titolare all’indirizzo di posta
      elettronica <a href="mailto:fmermani@gmail.com">fmermani@gmail.com</a>
    <h5>MODIFICHE</h5>
    <p>La societ&aacute; si riserva il diritto di modificare parzialmente o completamente la Politica sulla
      privacy o semplicemente di aggiornarne il contenuto.</p>
  </div>

</ng-template>
