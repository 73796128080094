<section class="activationSection d-flex justify-content-center align-items-center">
  <div class="container">
    <div class="row d-flex justify-content-center align-items-center reverse-reservare">
      <div class="col-sm-12 col-md-6 col-lg-6 text-center mb-5">
        <img src="assets/esercente.png" class="w-100 imgEsercente"/>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mb-2">
        <h2 class="sectionTitle text-center">
          Hai un attivita'?
        </h2>
        <p>Scegli RESERVARE, la scelta sicura a portata di Click!
          Reservare è un sistema di <b>prenotazione rapida online</b> per accessi
          in aree pubbliche e private e di monitoraggio delle presenze.
          Si tratta di un metodo <b>Semplice</b>, <b>Sicuro</b> e <b>Veloce</b> necessario per
          tenere sotto controllo I flussi d’ingresso presso la tua attività.
          Gli utenti che si registreranno su Reservare riceveranno un
          biglietto con i propri dati. Lo stesso, contenente un <b>codice QR</b>
          permetterà agli addetti di monitorare costantemente la presenza
          di persone nelle aree monitorate ottimizzando l’impiego delle
          risorse disponibili e fornendo dati statistici e report
          sull’andamento delle attività.
          Reservare è un’innovazione del settore e presenta <b>funzionalità
            uniche e fondamentali</b> per voi proprietari di attività:
        </p>
          <ul>
            <li>Sistema di prenotazione</li>
            <li>Gestione degli spazi</li>
            <li>Registro presenze</li>
            <li>Verifica delle prenotazioni grazie al codice QR</li>
          </ul>
        <h4 class="text-center mt-2">
          Reservare, la scelta adatta a te!
        </h4>
        <p class="callToAction text-center mt-3">
          <a routerLink="/subscription"><span><i class="fa fa-play"></i></span></a> <span
          class="callToActionLabel">SCOPRI DI PIU'</span>
        </p>
      </div>
    </div>
  </div>
</section>
