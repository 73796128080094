<div class="container">
  <div class="card w-100">
    <div class="card-reservare card-body" style="min-height: auto">
      <form method="post" id="payment-form">
        <div class="form-row m-0 mb-3">
          <div id="card-element"></div>
          <small id="card-errors" class="text-danger" role="alert"></small>
        </div>
      </form>
      <div class="row">
        <div class="col-12 text-right text-center-reservare">
          <button type="button" class="btn-reservare-step-back mr-2" (click)="onBack()"><span>Indietro</span></button>
          <button type="button" (click)="submitStripe()" class="btn-reservare-step-success mt-pay"><span>Effettua Pagamento&nbsp;<i
            class="fa fa-credit-card"></i></span></button>
        </div>
      </div>
    </div>
  </div>
</div>
