<div class="container">
  <div class="card w-100">
    <div class="card-body card-reservare">
      <form [formGroup]="anagraficaForm">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="name" class="control-label required">Nome</label>
              <input
                type="text"
                class="form-control"
                id="name"
                autocomplete="off"
                formControlName="nome"
                name="name"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="surname" class="control-label required">Cognome</label>
              <input
                type="text"
                class="form-control"
                id="surname"
                autocomplete="off"
                formControlName="cognome"
                name="surname"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="form-group">
              <label for="mobile" class="control-label required">Cellulare</label>
              <input
                type="text"
                class="form-control"
                id="mobile"
                autocomplete="off"
                formControlName="cellulare"
                name="mobile"
              />
              <small
                *ngIf="(anagraficaForm.get('cellulare').hasError('pattern') && anagraficaForm.get('cellulare').touched)"
                class="text-danger">Inserire un numero telefonico valido. Se specificato il prefisso internazionale usare "+39"</small>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="emails">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label for="mail" class="control-label required">Email</label>
              <input
                type="email"
                class="form-control"
                id="mail"
                autocomplete="off"
                formControlName="email"
                name="mail"
              />
              <small
                *ngIf="(anagraficaForm.get(['emails','email']).hasError('email') && anagraficaForm.get(['emails','email']).touched)"
                class="text-danger">Inserire una mail valida</small>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label for="confermaMail" class="control-label required">Conferma email</label>
              <input
                type="email"
                class="form-control"
                autocomplete="off"
                id="confermaMail"
                formControlName="confermaMail"
                name="confermaMail"
              />
              <small class="text-danger" *ngIf="anagraficaForm.get(['emails','email']).value != anagraficaForm.get(['emails','confermaMail']).value && anagraficaForm.get(['emails','confermaMail']).value != null">
                Le email non coincidono</small>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="passwords">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label for="password" class="control-label required">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                autocomplete="off"
                formControlName="password"
                name="password"
              />
              <small
              *ngIf="(anagraficaForm.get(['passwords','password']).hasError('pattern') && anagraficaForm.get(['passwords','password']).touched)"
              class="text-danger">La password deve essere compresa tra 8-20 caratteri, contenere almeno 1 lettera, 1 numero e 1 carattere speciale e non iniziare con un carattere speciale (!@#$%^&*()_+)</small>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label for="confermaPassword" class="control-label required">Conferma password</label>
              <input
                type="password"
                class="form-control"
                id="confermaPassword"
                autocomplete="off"
                formControlName="confermaPassword"
                name="confermaPassword"
              />
              <small class="text-danger" *ngIf="anagraficaForm.get(['passwords','password']).value != anagraficaForm.get(['passwords','confermaPassword']).value && anagraficaForm.get(['passwords','confermaPassword']).value != null">
              Le password non coincidono</small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-8 offset-sm-0 offset-md-2 offset-lg-2 text-center">
            <div class="form-group">
              <small [class.text-danger]="showPrivacyMandatory">
                Autorizzo il trattamento dei miei dati personali ai sensi del Decreto Legislativo 30 giugno 2003, n. 196
                “Codice in materia di protezione dei dati personali” e del GDPR (Regolamento UE 2016/679). Dichiaro,
                altresì che per i dati personali inseriti dal sottoscritto relativi ad altri soggetti, ho ricevuto
                apposita e specifica autorizzazione sollevando l’Amministrazione Comunale e la ditta erogatrice del
                servizio telematico da qualsivoglia responsabilità
              </small>
              <input
                type="checkbox"
                class="form-control mt-2 checkbox-reservare"
                id="details"
                autocomplete="off"
                formControlName="FLAGGDPR"
                (change)="onCheckboxChange($event)"
                name="details"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12 text-right text-center-reservare">
          <!--<button (click)="mock()">MOOOOCK</button>-->
          <button type="button" class="btn-reservare-step" (click)="onNext()" [disabled]="!anagraficaForm.valid"><span>Avanti</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
