<div class="mainScollingArea">
  <div class="container d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="card w-100">
      <div class="card-reservare card-body text-center" style="min-height: auto">
        <i class="far fa-thumbs-up text-success"></i>
        <h4 class="text-success">
          Registrazione Completata
        </h4>
        <p>
          La tua registrazione e' ora completa, puoi accedere al servizio utilizzando il link sottostante e utilizzare
          la tua mail e la password specificata nel processo di creazione come credenziali di accesso.
        </p>
        <a href="https://app.reservare.it/">
          <button type="button" class="btn-reservare-step mt-2"><span>Accedi al servizio</span></button>
        </a>
      </div>
    </div>
  </div>
</div>
