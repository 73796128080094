export class EsercenteLead {
  constructor(
    public codiceLingua: string,
    public password: string,
    public nome: string,
    public cognome: string,
    public cellulare: string,
    public email: string,
    public FLAGGDPR: number
  ) {}
  public id: number;
}
