import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';

import {AppComponent} from './app.component';
import {FooterComponent} from './components/footer/footer.component';
import {MainSectionComponent} from './components/main-section/main-section.component';
import {InfoSectionComponent} from './components/info-section/info-section.component';
import {ActivationSectionComponent} from './components/activation-section/activation-section.component';
import {HeaderComponent} from './components/header/header.component';
import {SocialComponent} from './components/social/social.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalModule} from 'ngx-bootstrap/modal';
import {RecaptchaModule} from 'angular-google-recaptcha';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegisterComponent} from './components/register/register.component';
import {StripeComponent} from './components/subscription-form/components/stripe/stripe.component';
import {SubscriptionFormComponent} from './components/subscription-form/subscription-form.component';
import {SubscriptionComponent} from './components/subscription/subscription.component';
import {AppRoutingModule} from './app-routing.module';
import {StripeModule} from 'stripe-angular';
import {HomeComponent} from './components/home/home.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {StepAnagraficiComponent} from './components/subscription-form/components/step-anagrafici/step-anagrafici.component';
import {StepStrutturaComponent} from './components/subscription-form/components/step-struttura/step-struttura.component';
import {StepFatturazioneComponent} from './components/subscription-form/components/step-fatturazione/step-fatturazione.component';
import {StepperComponent} from './components/subscription-form/components/stepper/stepper.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SuccessPageComponent } from './components/subscription-form/components/success-page/success-page.component';
import { SubscriptionInfoComponent } from './components/subscription-info/subscription-info.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MainSectionComponent,
    InfoSectionComponent,
    ActivationSectionComponent,
    HeaderComponent,
    SocialComponent,
    RegisterComponent,
    StripeComponent,
    SubscriptionFormComponent,
    SubscriptionComponent,
    HomeComponent,
    StepAnagraficiComponent,
    StepStrutturaComponent,
    StepFatturazioneComponent,
    StepperComponent,
    SuccessPageComponent,
    SubscriptionInfoComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ScrollToModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    RecaptchaModule.forRoot({
      siteKey: '6LektP0UAAAAAJf_wjlUj8VlPCC--QmGi10rfod_',
    }),
    AutocompleteLibModule,
    ToastrModule.forRoot(),
    StripeModule.forRoot(
      'pk_live_51GxCoLEnlGcY25tlyaHnBVZLzpC8j8phhZYfbufSnmMVKkC2LfS7NAM6So51Oy2nq7wjAhJqHzuX86k9vKmlVZ6t00wEBJ1N94'
    ),
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [StripeComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
