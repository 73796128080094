import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { EsercenteLead } from 'src/app/models/esercente/esercente-lead.impl';
import { HttpHeaders } from '@angular/common/http';
import { FullRegistration } from 'src/app/models/fullRregistration/fullRegistration.impl';

const httpOptions = {
  headers: new HttpHeaders({}),
};

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(private http: HttpClient) {
    httpOptions.headers = httpOptions.headers.set('Pragma', 'no-cache');
    httpOptions.headers = httpOptions.headers.set('Cache-Control', 'no-cache');
    httpOptions.headers = httpOptions.headers.set(
      'Content-Type',
      'application/json'
    );
  }

  base_url_taal = 'https://nmuwrvo9bd.execute-api.eu-west-1.amazonaws.com/Prod/api';
  //base_url_taal = 'http://62.94.39.70/prenoTaal.be/api';
  exec_url = '/Generic/exec';

  addEsercente(
    fullRegistration: FullRegistration
  ): Observable<FullRegistration> {
    return this.http.post<FullRegistration>(
      this.base_url_taal + this.exec_url + '/sp_RegisterEsercente',
      { par: fullRegistration },
      httpOptions
    );
  }

  addFullRegistration(fulRegistration: FullRegistration): Observable<any> {
    return this.http.post<EsercenteLead>(
      this.base_url_taal + this.exec_url + '/sp_SuperRegisterPrivato',
      { par: fulRegistration },
      httpOptions
    );
  }
}
