import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {RegisterService} from '../../services/registrazione/register.service';
import {DatiRegistrazione} from 'src/app/models/datiRegistrazione/datiRegistrazione.impl';
import {FullRegistration} from 'src/app/models/fullRregistration/fullRegistration.impl';
import {EsercenteService} from 'src/app/services/esercente/esercente.service';
import {ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {Subscription} from 'src/app/models/subscription/subscription';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  sub: Subscription;

  constructor(
    private esercenteService: EsercenteService,
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const extra = this.router.getCurrentNavigation().extras;
    if (extra.state && extra.state.subscription){
      this.sub = extra.state.subscription;
    } else {
      this.router.navigate(['subscription']);
    }
  }

  submitted: boolean = false;

  ngOnInit() {
  }

  onSubmit(datiRegistrazione: DatiRegistrazione) {
    stripe
      .redirectToCheckout({
        lineItems: [{price: 'price_1HSJPTEqcmqecCvXdwq83AMx', quantity: 1}],
        mode: 'subscription',
        // Do not rely on the redirect to the successUrl for fulfilling
        // purchases, customers may not always reach the success_url after
        // a successful payment.
        // Instead use one of the strategies described in
        // https://stripe.com/docs/payments/checkout/fulfillment
        successUrl: 'http://localhost:4200/opening-component',
        cancelUrl: 'http://localhost:4200/subscription-component',
      })
      .then((result) => {
        if (result.error) {
          const displayError = document.getElementById('error-message');
          displayError.textContent = result.error.message;
        }
      });

    this.submitted = true;
    this.createDatiRegistrazione(datiRegistrazione);
    const dati = this.createDatiRegistrazione(datiRegistrazione);
  }

  public createDatiRegistrazione(
    datiRegistrazione: DatiRegistrazione
  ): FullRegistration {
    const reg = new FullRegistration();
    //
    reg.CODICELINGUA = datiRegistrazione.esercente.codiceLingua;
    reg.PASSWORD = datiRegistrazione.esercente.password;
    reg.NOME = datiRegistrazione.esercente.nome;
    reg.COGNOME = datiRegistrazione.esercente.cognome;
    reg.EMAIL_ESERCENTE = datiRegistrazione.esercente.email;
    reg.CELLULARE = datiRegistrazione.esercente.cellulare;
    reg.FLAGGDPR = datiRegistrazione.esercente.FLAGGDPR;
    //
    reg.DESCRIZIONE = datiRegistrazione.attivita.nomeStruttura;
    reg.CODICETIPOLOGIAAREA = datiRegistrazione.attivita.descrizioneAttivita;
    reg.INDIRIZZO_AREA = datiRegistrazione.attivita.indirizzo;
    reg.CAP_AREA = datiRegistrazione.attivita.CAP;
    reg.TELEFONO_AREA = datiRegistrazione.attivita.telefono;
    reg.SITOWEB = datiRegistrazione.attivita.sitoWeb;
    reg.EMAIL_AREA = datiRegistrazione.attivita.mail;
    reg.IDCOMUNE_AREA = datiRegistrazione.attivita.idCitta;
    reg.CODICEPROVINCIA_AREA = datiRegistrazione.attivita.PROVINCIA;
    //
    reg.RAGIONESOCIALE = datiRegistrazione.datiFatturazione.ragioneSociale;
    reg.PARTITAIVA = datiRegistrazione.datiFatturazione.PIVA;
    reg.CODICEDESTINATARIO =
      datiRegistrazione.datiFatturazione.codiceDestinatario;
    reg.PEC = datiRegistrazione.datiFatturazione.PEC;
    reg.INDIRIZZO_FATTURAZIONE = datiRegistrazione.datiFatturazione.indirizzo;
    reg.CAP_FATTURAZIONE = datiRegistrazione.datiFatturazione.CAP;
    reg.TELEFONO_FATTURAZIONE = datiRegistrazione.datiFatturazione.telefono;
    reg.EMAIL_FATTURAZIONE = datiRegistrazione.datiFatturazione.mail;
    reg.IDCOMUNE_FATTURAZIONE = datiRegistrazione.datiFatturazione.idCitta;
    reg.CODICEPROVINCIA_FATTURAZIONE =
      datiRegistrazione.datiFatturazione.PROVINCIA;
    //
    return reg;
  }
}
