<div class="mainScollingArea">
  <section class="d-flex justify-content-center align-items-center abbonamento-reservare">
    <div class="container text-center">
      <h1 class="mainSectionSubTitle pt-5 toppadding-reservare">Scegli il tuo abbonamento...</h1>
      <div class="row d-flex align-items-center mt-5">
        <div
          *ngFor="let sub of subscriptions"
          class="col-sm-12 col-md-6 col-lg-4 mb-5 mr-auto ml-auto"
        >
          <div class="card">
            <div class="card-body card-reservare">
              <div class="d-flex align-items-center flex-column text-center" style="height: 320px">
                <h4 class="name">{{ sub.name }}</h4>
                <p class="price mt-auto">{{ sub.price }}</p>
                <div class="mt-auto w-100">
                  <button type="button" class="btn-reservare-step w-100" (click)="subscribe(sub)">
                    <span>Seleziona</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
