import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { EsercenteLead } from 'src/app/models/esercente/esercente-lead.impl';
import { HttpHeaders } from '@angular/common/http';
import { FullRegistration } from 'src/app/models/fullRregistration/fullRegistration.impl';

const httpOptions = {
  headers: new HttpHeaders({}),
};

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private http: HttpClient) {
    httpOptions.headers = httpOptions.headers.set('Pragma', 'no-cache');
    httpOptions.headers = httpOptions.headers.set('Cache-Control', 'no-cache');
    httpOptions.headers = httpOptions.headers.set(
      'Content-Type',
      'application/json'
    );
  }

  base_url_taal = 'https://nmuwrvo9bd.execute-api.eu-west-1.amazonaws.com/Prod/api';
  //base_url_taal = 'http://62.94.39.70/prenoTaal.be/api';
  //base_url_taal = 'http://192.168.128.134:44315/api';
  exec_url = '/Generic/exec';

  get(nomeLookup: string) {
    return this.http.post(
      this.getNomeStored('fn', 'Get', nomeLookup),
      { par: null, pageNum: 0, order: [] },
      httpOptions
    );
  }

  public getNomeStored(
    type: string,
    operation: string,
    entity: string
  ): string {
    return (
      this.base_url_taal + this.exec_url + '/' + type + '_' + operation + entity
    );
  }
}
