export class DatiFatturazione {
  constructor(
    public ragioneSociale: string,
    public PIVA: string,
    public codiceDestinatario: string,
    public PEC: string,
    public indirizzo: string,
    public CAP: string,
    public idCitta: number,
    public PROVINCIA: string,
    public telefono: string,
    public mail: string
  ) {}
  public id: number;
}
